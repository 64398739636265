<template>
    <div class="search_filter_wrap" style="margin-top: 30px">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="filterInputOption" class="my_filter select w150"
                           popper-class="category_select"
                           :popper-append-to-body="false">
                    <el-option
                        v-for="option in filterInputOptions"
                        :key="`filterInputOption${option.value}`"
                        :label="$t(option.name)"
                        :value="option.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="filterInput"
                          @keypress.enter.native="runFilter(filterInputOption, filterInput)"/>
                <button class="search_btn" @click="runFilter(filterInputOption, filterInput)"><span>{{$t('search')}}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <!--        <button class="filter_btn two" @click="changeCheck('filterEndCheck')"></button>-->
                <el-popover
                    popper-class="top_722"
                    placement=""
                    width="435"
                    trigger="click"
                    :open-delay="300"
                    :offset="0"
                    :append-to-body="false"
                    :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two" @click="changeCheck('filterEndCheck')"></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg popup_bg_radius">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{$t('status')}}</div>
                                <div class="status_wrap">
                                    <el-checkbox-group v-model="filterStatus"
                                                       @change="runFilter('st', filterStatus)">
                                        <el-checkbox v-for="status in detectionStatus" :key="`filterStatus${status.value}`" :label="status.value">{{ $t(status.name) }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="sub_title">{{$t('request')}} {{$t('dateTime')}}</div>
                                <div class="price_wrap">
                                    <el-date-picker
                                        class="range_time"
                                        v-model="filterDate"
                                        type="daterange"
                                        range-separator="~"
                                        value-format="yyyy-MM-dd"
                                        @change="changeDateFilter('cr', filterDate)"
                                        start-placeholder="YYYY-MM-DD"
                                        end-placeholder="YYYY-MM-DD">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script>
import detectionUtil from "@/components/detection/detectionUtil";
import {detectionStatus} from "@/service/detectionService";
import util from "@/mixins/util";

const {filterConfig} = detectionUtil;
export default {
    name: "DetectionFilterRequestLayout",
    mixins: [],
    components: {
    },
    inject: ['setFilter'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return{
            filterStatus: [],
            filterDate: [],
            filterInput: '',
            filterInputOption: 'na',
            filterInputOptions: [
                {
                    value: 'na',
                    name: 'subject',
                },{
                    value: 'uid',
                    name: 'request_num',
                },{
                    value: 'do',
                    name: 'detection_domain',
                }
            ],
            detectionStatus: detectionStatus,



            searchOption: 'ina',
            sortOption: 'i.updated_at',
            searchInput: '',
            filterEndCheck: false,

            closingCheck: false,
            processCheck: false,
            requestCheck: false,
            readyCheck: false,
            civilCheck: false,
            cancelRequestCheck: false,

            inputDate: '',

        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        runFilter(key, value) {
            this.setFilter(key, value);
        },
        changeDateFilter() {
            if(util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            }else{
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
